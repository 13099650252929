import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Link from 'next/link';
import toFit from '@js/toFit';
import { URLS } from '@js/constants';

const position = {
  left: 0,
};

export const menus = {
  resources: [
    {
      title: '🇺🇸 English',
      href: 'https://www.youtube.com/playlist?list=PLy4oenOJQtTr4h9PUfHRU-luZQGie6lZz',
      target: '_blank',
    },
    // {
    //   title: '🇰🇷 한국어',
    //   href: 'https://www.youtube.com/playlist?list=PLy4oenOJQtToR9SrWCvqMXn7SgSaHgSb6',
    //   target: '_blank',
    // },
    {
      title: '🇹🇭 ภาษาไทย',
      href: 'https://www.youtube.com/playlist?list=PLy4oenOJQtTr2Wxf6Lu1ZtCJXq8Aga3uB',
      target: '_blank',
    },
  ],
};

const GlobalLinks = ({
  onSolutionsLinkClicked,
  onResourcesLinkClick,
  onUseCasesLinkClicked,
  isMenu = false,
  t,
}) => {
  const router = useRouter();
  const ref = useRef();
  const activeRef = useRef();
  const { pathname } = router;
  const [activeLeft, setActiveLeft] = useState(position.left);

  const setCurrentLink = () => {
    const { current } = ref;
    if (current) {
      const point = current.querySelector('.nav-link.active');
      let delay = 0;

      if (!activeLeft || activeLeft <= 0) {
        delay = 250;
      }

      setTimeout(() => {
        if (point) {
          const offLeft = point.offsetLeft + point.clientWidth / 2;
          setActiveLeft(offLeft);
        }
      }, delay);
    }
  };

  const resize = () => {
    setCurrentLink();
  };

  // DidMountComponent
  useEffect(() => {
    setCurrentLink();
    window.addEventListener('resize', toFit(resize, { passive: true }));
  }, []);

  useEffect(() => {
    if (ref?.current) {
      const { current } = ref;
      const point = current.querySelector('.nav-link.active');
      if (point) {
        const left = point.offsetLeft + point.clientWidth / 2;
        setActiveLeft(left);
      }
    }

    return () => {
      setActiveLeft(0);
    };
  }, [ref, pathname]);

  const handleClick = (link) => {
    switch (link) {
      case 'SOLUTIONS':
        if (onSolutionsLinkClicked) {
          onSolutionsLinkClicked();
        }
        break;
      case 'USE-CASES':
        if (onUseCasesLinkClicked) {
          onUseCasesLinkClicked();
        }
        break;
      default:
        break;
    }
  };

  const handleMouseOver = () => {
    let ele = null;
    ele = window?.document?.getElementsByClassName('me-body')[0];
    if (ele) ele.classList.add('body-overlay');
  };

  const handleMouseOut = () => {
    let ele = null;
    ele = window?.document?.getElementsByClassName('me-body')[0];
    if (ele) ele.classList.remove('body-overlay');
  };

  return (
    <div ref={ref} className="rel-div">
      <a
        className={`nav-link solutions ${
          (pathname.includes('/solutions') ||
            pathname.includes('/use-cases')) &&
          !isMenu
            ? 'active'
            : ''
        }`}
        title="Solutions"
        target="_self"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={() => handleClick('SOLUTIONS')}
        onKeyDown={() => handleClick('SOLUTIONS')}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        role="button"
        onFocus={() => {}}
        onBlur={() => {}}
        tabIndex={0}
      >
        Solutions {/* <div className="solution-items w-[608px]"> */}
        {/*   <div className="grid grid-cols-2 gap-2"> */}
        <div className="solution-items w-[254px]">
          <div className="">
            <ul className="menu">
              {/* <li className="text-sm font-bold text-[#A9AFB5] uppercase title cursor-auto"> */}
              {/*   By Solution */}
              {/* </li> */}
              <Link href="/solutions/hli-x">
                <li
                  className={`${
                    pathname === '/solutions/hli-x' ? 'active' : ''
                  }`}
                >
                  HLI-X
                </li>
              </Link>
              <Link href="/solutions/hli-automate">
                <li
                  className={`${
                    pathname === '/solutions/hli-automate' ? 'active' : ''
                  }`}
                >
                  HLI-Automate
                </li>
              </Link>
            </ul>
            {/* <ul className="menu"> */}
            {/*   <li className="text-sm font-bold text-[#A9AFB5] uppercase title cursor-auto"> */}
            {/*     By Use Case */}
            {/*   </li> */}
            {/*   <Link href="/solutions/customer-support-automation"> */}
            {/*     <li */}
            {/*       className={`${ */}
            {/*         pathname === '/solutions/customer-support-automation' */}
            {/*           ? 'active' */}
            {/*           : '' */}
            {/*       }`} */}
            {/*     > */}
            {/*       Customer Support Automation */}
            {/*     </li> */}
            {/*   </Link> */}
            {/*   <Link href="/solutions/product-search-and-order"> */}
            {/*     <li */}
            {/*       className={`${ */}
            {/*         pathname === '/solutions/product-search-and-order' */}
            {/*           ? 'active' */}
            {/*           : '' */}
            {/*       }`} */}
            {/*     > */}
            {/*       Product Search and Order */}
            {/*     </li> */}
            {/*   </Link> */}
            {/*   <Link href="/solutions/marketing-and-sales"> */}
            {/*     <li */}
            {/*       className={`${ */}
            {/*         pathname === '/solutions/marketing-and-sales' */}
            {/*           ? 'active' */}
            {/*           : '' */}
            {/*       }`} */}
            {/*     > */}
            {/*       Marketing and Sales */}
            {/*     </li> */}
            {/*   </Link> */}
            {/*   <Link href="/use-cases/food-ordering"> */}
            {/*     <li */}
            {/*       className={`${ */}
            {/*         pathname === '/use-cases/food-ordering' ? 'active' : '' */}
            {/*       }`} */}
            {/*     > */}
            {/*       Food Ordering */}
            {/*     </li> */}
            {/*   </Link> */}
            {/*   <li className="mt-2 text-sm font-bold text-[#A9AFB5] title uppercase cursor-auto"> */}
            {/*     By Industry */}
            {/*   </li> */}
            {/*   <Link href="/use-cases/ecommerce"> */}
            {/*     <li */}
            {/*       className={`${ */}
            {/*         pathname === '/use-cases/ecommerce' ? 'active' : '' */}
            {/*       }`} */}
            {/*     > */}
            {/*       eCommerce */}
            {/*     </li> */}
            {/*   </Link> */}
            {/*   <Link href="/use-cases/telecommunications"> */}
            {/*     <li */}
            {/*       className={`${ */}
            {/*         pathname === '/use-cases/telecommunications' ? 'active' : '' */}
            {/*       }`} */}
            {/*     > */}
            {/*       Telecommunications */}
            {/*     </li> */}
            {/*   </Link> */}
            {/* </ul> */}
          </div>
        </div>
      </a>

      <Link href="/case-studies">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className={`nav-link mr-8 case-studies ${
            pathname.includes('/case-studies/') || pathname === '/case-studies'
              ? 'active'
              : ''
          }`}
          title="Case Studies"
          target="_self"
          rel="noreferrer"
        >
          Case Studies
        </a>
      </Link>

      {/* <Link href="/pricing"> */}
      {/*   <a */}
      {/*     className={`nav-link mr-8 ${ */}
      {/*       pathname.includes('/pricing/') || pathname === '/pricing' */}
      {/*         ? 'active' */}
      {/*         : '' */}
      {/*     }`} */}
      {/*     title="Pricing" */}
      {/*     target="_self" */}
      {/*     rel="noreferrer" */}
      {/*   > */}
      {/*     Pricing */}
      {/*   </a> */}
      {/* </Link> */}

      <a
        className="nav-link solutions"
        title="Resources"
        target="_self"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={onResourcesLinkClick}
        onKeyDown={onResourcesLinkClick}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        role="button"
        onFocus={() => {}}
        onBlur={() => {}}
        tabIndex={0}
      >
        Resources{' '}
        <ul className="solution-items w-[248px]">
          <a
            key="Documentation"
            rel="noreferrer"
            href={URLS.DOCS}
            target="_blank"
          >
            <li>Documentation</li>
          </a>
          <li className="mt-2 text-sm font-bold text-[#A9AFB5] title cursor-auto">
            Tutorials
          </li>
          {menus.resources.map(({ title, href, target }) => (
            <a key={title} href={href} target={target}>
              <li>{title}</li>
            </a>
          ))}
        </ul>
      </a>

      <Link href="/contact">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className={`nav-link mr-8 ${
            pathname.includes('/contact') ? 'active' : ''
          }`}
          title="Contact"
          target="_self"
        >
          Contact
        </a>
      </Link>

      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className={`nav-link ${pathname.includes('/company') ? 'active' : ''}`}
        title="Company"
        target="_blak"
        href={process.env.NEXT_PUBLIC_MINDAI_URL}
      >
        Company
      </a>

      {activeLeft && activeLeft > 0 ? (
        <span
          id="nav-active-pointer"
          ref={activeRef}
          style={{ left: `${activeLeft}px` }}
        />
      ) : (
        ''
      )}
      <style jsx>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';

          .solution-items {
            display: none;
          }

          a.nav-link {
            @include navLinkStyle('MOBILE');
            font-weight: 700;
            font-size: 15px;
            line-height: 20px;
          }

          #nav-active-pointer {
            opacity: 0;
            visibility: hidden;
          }

          .menu .active {
            position: relative;
            &:before {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              content: '';
              width: 4px;
              height: 24px;
              margin-top: auto;
              margin-bottom: auto;
              background-color: #f7b716;
            }
            &:hover {
              cursor: default;
            }
          }

          @media all and (min-width: $resolution_d_min) {
            .solutions {
              position: relative;
              cursor: pointer;
            }

            .solution-items {
              display: none;
              position: absolute;
              top: 70px;
              font-size: 15px;
              font-weight: bold;
              background-color: #fff;
              padding: 8px 0;
              cursor: pointer;
            }

            .solutions:hover .solution-items {
              display: block;
            }
            .solution-items li {
              padding: 12px 0 12px 24px;
              line-height: 20px;

              &:not(.title):not(.active):hover {
                background: #f4f4f4;
              }
            }

            a.nav-link {
              @include navLinkStyle('DESKTOP');
              padding: 18px 0 18px;

              &.docs,
              &.solutions,
              &.docs:active,
              &.solutions:active {
                margin-right: 32px;
                &:after {
                  display: none;
                }
              }
              &:hover {
                &:before {
                  bottom: 15px;
                }
              }

              &.active {
                &:before {
                  background-color: #f7b716;
                  bottom: 15px;
                }
                &:hover {
                  &:before {
                    background-color: $black;
                    bottom: 15px;
                  }
                }
              }

              @media (hover: hover) {
                &.docs:hover {
                  margin-right: 32px;
                  &:after {
                    display: none;
                  }
                }
              }
            }
          }

          a.nav-dark {
            color: #b1b5b9;
            @media all and (max-width: 1365px) {
              color: $black;
            }

            &:active,
            &:hover {
              color: #fff;
              @media all and (max-width: 1365px) {
                color: $black;
              }
              &:before {
                background-color: $white;
              }
            }
            &.active {
              color: #fff;
              &:hover {
                &:before {
                  background-color: $white;
                }
              }
            }
            .solution-items {
              color: $black;
            }
          }
        `}
      </style>
    </div>
  );
};

GlobalLinks.propTypes = {
  t: PropTypes.func.isRequired,
  onSolutionsLinkClicked: PropTypes.func,
  onResourcesLinkClick: PropTypes.func,
  onUseCasesLinkClicked: PropTypes.func,
  isMenu: PropTypes.bool,
};

export default GlobalLinks;
